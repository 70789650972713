/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    max-width: 100vw; /* Full width */
    max-height: 100vh; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.6); /* Black w/ opacity */
    z-index: 9999;
  }
  
  /* Modal Content */
  .modal-content {
    background-color: rgba(0,0,0,0.6);
    backdrop-filter: blur(5px);
    margin: auto;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.8);
    width: 80vw;
    height: 100vh;
    overflow: auto;
    scrollbar-width: none;
  }
  .modal-content::-webkit-scrollbar{width: 0; height: 0;}

  
  /* The Close Button */
  .close {
    color: white;
    font-size: 35px;
    font-weight: bold;
    position: fixed;
    top: 0;
    left: 20px;
  }
  
  .close:hover,
  .close:focus {
    color: orange;
    text-decoration: none;
    transition: 700ms;
    cursor: pointer;
  }

@media (max-width:600px) {
    .modal-content {
        padding: 5px;
        width: 100vw;
      }
    
}